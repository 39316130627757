
    /*.goods-wrapper {*/
    /*    .goods-box {*/
    /*        display: flex;*/
    /*        align-items: center;*/
    /*        .goods-cover {*/
    /*            width: 48px;*/
    /*            height: 48px;*/
    /*            display: flex;*/
    /*            align-items: center;*/
    /*            justify-content: center;*/
    /*            margin-right: 10px;*/
    /*            border: 1px solid #eee;*/
    /*            box-sizing: border-box;*/
    /*            img {*/
    /*                max-width: 100%;*/
    /*                max-height: 100%;*/
    /*            }*/
    /*        }*/
    /*        .goods-right {*/
    /*            width: 1%;*/
    /*            flex: 1;*/
    /*            .goods-title {*/
    /*                color: #333;*/
    /*                line-height: 18px;*/
    /*                transition: all .3s;*/
    /*                &:hover {*/
    /*                    color: #FD4446;*/
    /*                }*/
    /*            }*/

    /*        }*/
    /*    }*/
    /*}*/
    ::v-deep .el-table {
        min-height: calc(100vh - 328px);
        .el-table__body-wrapper {
            .el-table__row {
                .ranking {
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    font-weight: 500;
                    background-size: cover;
                    display: inline-block;
                }
                &:first-child {
                    .ranking {
                        background: url("../../../assets/images/dataCenter/one.png") no-repeat center;
                    }
                }
                &:nth-of-type(2) {
                    .ranking {
                        background: url("../../../assets/images/dataCenter/two.png") no-repeat center;
                    }
                }
                &:nth-of-type(3) {
                    .ranking {
                        background: url("../../../assets/images/dataCenter/three.png") no-repeat center;
                    }
                }
            }
        }
    }
